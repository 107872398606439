import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Autocomplete } from "@mui/material";
import { Checkbox, Paper } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "leaflet/dist/leaflet.css";
import "react-toastify/dist/ReactToastify.css";
import L from "leaflet";
import redPinIcon from "../../../Assets/images/pin2.png";
import Topbar from "../../global/Topbar";
import { fetchViewAdminPjp } from "../../../Redux/Slice/Admin/Pjp/ViewAdminPjpSlice";

export default function PjpOnMap() {
  const dispatch = useDispatch();
  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [30, 35],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]); // Use an array for multiple selections

  const AllPjp = useSelector(
    (state) => state.ViewAdminPjp.ViewAdminPjpData?.data
  );

  React.useEffect(() => {
    dispatch(fetchViewAdminPjp());
  }, [dispatch]);

  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue); // Update selectedEmpCodes
  };

  const [mapCenter, setMapCenter] = React.useState([20, 0]); // Default center
  const [mapZoom, setMapZoom] = React.useState(2); // Default zoom

  const calculateMapBounds = () => {
    if (!AllPjp) return; // Check if AllPjp is undefined
    const usersWithCoordinates = AllPjp.filter((item) => {
      const latitude = parseFloat(item.client?.latitude);
      const longitude = parseFloat(item.client?.longitude);
      return !isNaN(latitude) && !isNaN(longitude);
    });

    if (selectedEmpCodes.length === 1) {
      const selectedUser = usersWithCoordinates.find(
        (item) => item.pjp?.emp_code === selectedEmpCodes[0]
      );
      if (selectedUser) {
        const latitude = parseFloat(selectedUser.client?.latitude);
        const longitude = parseFloat(selectedUser.client?.longitude);
        if (!isNaN(latitude) && !isNaN(longitude)) {
          setMapCenter([latitude, longitude]);
          setMapZoom(12); // Zoom level to your preference
        }
      }
    } else {
      // Reset to default center and zoom if multiple users are selected
      setMapCenter([20, 0]);
      setMapZoom(2);
    }
  };

  React.useEffect(() => {
    calculateMapBounds(); // Calculate map bounds when selectedEmpCodes changes
  }, [selectedEmpCodes]);

  // Filter out duplicate empCodes
  const uniqueEmpCodes = Array.from(
    new Set(
      AllPjp
        ? AllPjp.filter((client) => client.pjp && client?.pjp?.emp_code).map(
            (client) => client?.pjp?.emp_code
          )
        : []
    )
  );

  React.useEffect(() => {
    console.log("selectedEmpCodes:", selectedEmpCodes);
    console.log("AllPjp:", AllPjp);
  }, [selectedEmpCodes, AllPjp]);

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Pjp On Map</h4>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              multiple
              options={uniqueEmpCodes}
              disableCloseOnSelect
              onChange={handleEmpCodeChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "6px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    placeholder="Select User"
                    style={{ width: "170px" }}
                  />
                </Paper>
              )}
            />
          </div>
        </div>
        <MapContainer
          key={`${mapCenter[0]}-${mapCenter[1]}-${mapZoom}`}
          style={{
            height: "530px",
            width: "95%",
            border: "2px solid #ccc",
            marginTop: "10px",
          }}
          center={mapCenter}
          zoom={mapZoom}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {AllPjp &&
            AllPjp.filter((item) =>
              selectedEmpCodes.includes(item?.pjp?.emp_code)
            ).map((item) => {
              const latitude = parseFloat(item.client?.latitude);
              const longitude = parseFloat(item.client?.longitude);
              console.log("Latitude:", latitude, "Longitude:", longitude);
              if (isNaN(latitude) || isNaN(longitude)) {
                console.error("Invalid latitude or longitude:", item.client);
                return null;
              }

              return (
                <Marker
                  key={item.client?.id}
                  position={[latitude, longitude]}
                  icon={locationOnIcon}
                >
                  <Popup>
                    <div>
                      <p>Emp Name: {item.user?.name}</p>
                      <p>Emp Code: {item.user?.empCode}</p>
                      <p>Firm Name: {item.pjp?.firm_name}</p>
                    </div>
                  </Popup>
                </Marker>
              );
            })}
        </MapContainer>

        <ToastContainer position="bottom-right" autoClose={3000} />
      </div>
    </>
  );
}
