import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";

import { addAdminBrand } from "../../../Redux/Slice/Admin/Brand/AddBrandSlice";
import { UpdateAdminBrand } from "../../../Redux/Slice/Admin/Brand/UpdateBrandSlice";
import { fetchViewAdminBrand } from "../../../Redux/Slice/Admin/Brand/ViewBrandSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Settings() {
  const dispatch = useDispatch();

  const brandData = useSelector(
    (state) => state.ViewBrand.ViewAdminBrandData?.data
  );

  const loading = useSelector((state) => state.ViewBrand.isLoader);
  const error = useSelector((state) => state.ViewBrand.isError);
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);

  // view list
  React.useEffect(() => {
    dispatch(fetchViewAdminBrand());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // add Brand
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formData, setFormData] = useState({
    name: "",
  });
  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Brand Name is required";
    }

    return errors;
  };
  const [formErrors, setFormErrors] = useState({
    name: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleForm = () => {
    // If form submission is already in progress, prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Set form submission in progress
    setIsSubmitting(true);

    const formDataWithDefaults = {
      ...formData,
    };

    dispatch(addAdminBrand(formDataWithDefaults))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          name: "",
        });
        setFormErrors({});

        // Reload data after successful addition
        dispatch(fetchViewAdminBrand());

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Brand:", error);
        toast.error("Error adding Brand. Please try again.");
      })
      .finally(() => {
        // Reset form submission status
        setIsSubmitting(false);
      });
  };

  const handleChange = (e) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      device_time: formattedDate,
    });
  };

  //   update
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState({
    id: "",
    name: "",
  });

  const handleEditModalChange = (field, value) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
      device_time: formattedDate, // Include device_time in the state update
    }));
  };

  const handleEdit = () => {
    const { id, name } = editData;

    const credentials = {
      id,
      name,
    };

    dispatch(UpdateAdminBrand(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewAdminBrand());

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Brand:", error);
        toast.error("Error updating Brand. Please try again.");
      });
  };

  const handleOpenEditModal = (brandData) => {
    if (brandData) {
      setSelectedData(brandData);
      setEditData({
        id: brandData.id,
        name: brandData.name,
      });
    } else {
      setEditData({
        id: "",
        name: "",
      });
    }
    setOpenEditModal(true);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = brandData?.filter(
    (Brand) =>
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(Brand?.name)) &&
      Brand.name.toLowerCase().includes(searchTerm)
  );

  // Slice the data for pagination
  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Brand
          </Button>

          <h4 style={{ color: "#ffffff" }}>Brand Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputBase
              sx={{ ml: 2, width: "150px" }}
              placeholder="Search Brand Name"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
                marginRight: "10px",
              }}
            />
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : brandData && brandData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Brand Id
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Brand Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((brandData, index) => (
                    <StyledTableRow key={brandData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>{brandData.id}</StyledTableCell>{" "}
                      <StyledTableCell>{brandData?.name}</StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(brandData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, brandData.length]}
              component="div"
              count={brandData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Brand available</div>
        )}

        <Dialog open={openModal} maxWidth="sm" fullWidth>
          <DialogTitle
            sx={{
              background: "linear-gradient(to right, #fff, #bfbfbf)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Add Barnd</span>
              <IconButton color="secondary" onClick={handleCloseModal}>
                <CancelIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h5>Brand Name *</h5>
                    <TextField
                      label="Brand Name"
                      fullWidth
                      margin="normal"
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      error={!!formErrors.name}
                      helperText={formErrors.name}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>

          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleForm}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEditModal} maxWidth="sm" fullWidth>
          <DialogTitle
            sx={{
              background: "linear-gradient(to right, #fff, #bfbfbf)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Update Brand</span>
              <IconButton color="secondary" onClick={handleCloseEditModal}>
                <CancelIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    id="eid"
                    value={selectedData ? selectedData.id : ""}
                  />
                  <Grid item xs={12}>
                    <h5>Brand Name *</h5>
                    <TextField
                      id="name"
                      label="Brand Name"
                      defaultValue={selectedData ? selectedData.name : ""}
                      onChange={(e) =>
                        handleEditModalChange("name", e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      error={!!formErrors.name}
                      helperText={formErrors.name}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>
          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseEditModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEdit}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
