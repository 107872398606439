import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewAdminBrand = createAsyncThunk(
  "ViewAdminBrand/fetchViewAdminBrand",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/admin/brand`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewAdminBrandSlice = createSlice({
  name: "ViewAdminBrand",
  initialState: {
    ViewAdminBrandData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewAdminBrand.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewAdminBrand.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewAdminBrandData = action.payload;
      //   console.log("Viewcattt data:", action.payload);
    });
    builder.addCase(fetchViewAdminBrand.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewAdminBrandSlice.reducer;
