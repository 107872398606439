import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Cell,
} from "recharts";
import Topbar from "../../global/Topbar";
import { fetchViewAdminVisits } from "../../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";
import { fetchViewOrder } from "../../../Redux/Slice/Order/ViewOrderSlice";
import TopProductsCard from "./TopProducts";
import TopSalesCard from "./TopSalesman";
import TopDealerCard from "./TopDealer";
import TopSubDealerCard from "./TopSubdealer";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween"; // Import the isBetween plugin
dayjs.extend(isBetween); // Extend dayjs with isBetween

const calculateTopProducts = (filteredOrders) => {
  if (!filteredOrders || !Array.isArray(filteredOrders)) {
    return [];
  }

  const productQuantities = {};

  // Loop through orders and calculate total quantity for each product
  filteredOrders.forEach((order) => {
    if (Array.isArray(order.orderHistory)) {
      order.orderHistory.forEach((history) => {
        const productId = history.product_id;
        const productName = history.name;
        const qty = parseInt(history.qty, 10);

        if (!productQuantities[productId]) {
          productQuantities[productId] = {
            product_id: productId,
            product_name: productName,
            total_qty: 0,
          };
        }

        productQuantities[productId].total_qty += qty;
      });
    }
  });

  // Convert to array and sort by total quantity
  const sortedProducts = Object.values(productQuantities).sort(
    (a, b) => b.total_qty - a.total_qty
  );

  return sortedProducts.slice(0, 10); // Top 10
};

const Reports = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchViewAdminVisits());
    dispatch(fetchViewOrder());
  }, [dispatch]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const orderData = useSelector((state) => state.ViewOrder.ViewOrderData?.data);
  const Visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );
  const filterByDateRange = (data, dateField) => {
    if (!startDate && !endDate) return data;

    const start = startDate ? dayjs(startDate).startOf("day") : dayjs(0); // Start at beginning of day
    const end = endDate
      ? dayjs(endDate).endOf("day")
      : dayjs().add(1000, "years"); // End at end of day

    return data.filter((item) => {
      const itemDate = dayjs(item[dateField]);
      return itemDate.isBetween(start, end, null, "[]"); // Inclusive of start and end dates
    });
  };

  const filteredOrders = orderData
    ? filterByDateRange(orderData, "order.created_at")
    : [];
  const filteredVisits = Visits
    ? filterByDateRange(Visits, "visits.created_at")
    : [];
  console.log("filteredOrders", filteredOrders);
  console.log("filteredVisits", filteredVisits);
  const [filter, setFilter] = useState("quantity");
  const topProducts = calculateTopProducts(filteredOrders);

  const graphData = topProducts.map((product) => ({
    name: product.product_name,
    quantity: product.total_qty,
  }));
  const getCompletedFirms = (visitData) => {
    if (!visitData || !Array.isArray(visitData)) {
      return [];
    }

    return visitData.filter(
      (visit) =>
        visit.visits.payment_status !== "No" ||
        visit.visits.order_recipt !== "No"
    );
  };
  const completedFirms = getCompletedFirms(filteredVisits);
  const findFirmWithHighestCount = (completedFirms) => {
    const firmCount = {};

    // Count the occurrences of each firm
    completedFirms.forEach((firm) => {
      const firmName = firm.visits.firm_name;
      if (!firmCount[firmName]) {
        firmCount[firmName] = 0;
      }
      firmCount[firmName]++;
    });

    // Find the firm with the maximum count
    let maxCount = 0;
    let firmWithMaxCount = null;

    Object.entries(firmCount).forEach(([firmName, count]) => {
      if (count > maxCount) {
        maxCount = count;
        firmWithMaxCount = firmName;
      }
    });

    return {
      firmName: firmWithMaxCount,
      count: maxCount,
    };
  };
  const { firmName, count } = findFirmWithHighestCount(completedFirms);
  const calculateTopSalesmen = (visitData) => {
    if (!visitData || !Array.isArray(visitData)) {
      return [];
    }

    const salesmenCount = {};

    visitData.forEach((visit) => {
      const salesmanName = visit.visits.user_name.name; // Change based on data structure
      const isCompleted =
        visit.visits.payment_status !== "No" ||
        visit.visits.order_recipt !== "No";

      if (isCompleted) {
        if (!salesmenCount[salesmanName]) {
          salesmenCount[salesmanName] = {
            salesman_name: salesmanName,
            count: 0,
          };
        }
        salesmenCount[salesmanName].count += 1;
      }
    });

    // Sort and get the top 10
    const sortedSalesmen = Object.values(salesmenCount).sort(
      (a, b) => b.count - a.count
    );

    return sortedSalesmen.slice(0, 10);
  };

  const topSales = calculateTopSalesmen(filteredVisits);
  const graphData2 = topSales.map((sales) => ({
    name: sales.salesman_name,
    quantity: sales.count,
  }));

  const calculateTotalQuantityByFirm = (filteredOrders) => {
    if (!filteredOrders || !Array.isArray(filteredOrders)) {
      return {};
    }

    const firmQuantities = {};

    // Loop through orders and only consider those with category_id = "3"
    filteredOrders.forEach((order) => {
      if (order.order.category_id === "3") {
        const firmId = order.order.firm_id;
        const firmName = order.order.firm_name;

        // Initialize if firm is not already in the dictionary
        if (!firmQuantities[firmId]) {
          firmQuantities[firmId] = {
            firm_name: firmName,
            total_qty: 0,
          };
        }

        // Add the quantity from order history
        order.orderHistory.forEach((history) => {
          const qty = parseInt(history.qty, 10);
          firmQuantities[firmId].total_qty += qty;
        });
      }
    });

    return firmQuantities;
  };

  const findFirmWithMostQty = (firmQuantities) => {
    let maxQty = 0;
    let firmWithMostQty = null;

    Object.values(firmQuantities).forEach((firm) => {
      if (firm.total_qty > maxQty) {
        maxQty = firm.total_qty;
        firmWithMostQty = firm;
      }
    });

    return firmWithMostQty;
  };
  const firmQuantities = calculateTotalQuantityByFirm(filteredOrders);

  const firmWithMostQty = findFirmWithMostQty(firmQuantities);

  const graphDataFirm = Object.values(firmQuantities).map((firm) => ({
    name: firm.firm_name,
    quantity: firm.total_qty,
  }));
  const getTopDealers = (firmQuantities) => {
    const firmsArray = Object.values(firmQuantities);

    // Sort by total quantity in descending order and take the top 10
    const topDealers = firmsArray
      .sort((a, b) => b.total_qty - a.total_qty)
      .slice(0, 10);

    return topDealers;
  };
  const topDealers = getTopDealers(firmQuantities);
  // ==============subdealer
  const calculateTotalQuantityBySubdealer = (filteredOrders) => {
    if (!filteredOrders || !Array.isArray(filteredOrders)) {
      return {};
    }

    const SubdealerQuantities = {};

    // Loop through orders and only consider those with category_id = "3"
    filteredOrders.forEach((order) => {
      if (order.order.category_id === "4") {
        const firmId = order.order.firm_id;
        const firmName = order.order.firm_name;

        // Initialize if firm is not already in the dictionary
        if (!SubdealerQuantities[firmId]) {
          SubdealerQuantities[firmId] = {
            firm_name: firmName,
            total_qty: 0,
          };
        }

        // Add the quantity from order history
        order.orderHistory.forEach((history) => {
          const qty = parseInt(history.qty, 10);
          SubdealerQuantities[firmId].total_qty += qty;
        });
      }
    });

    return SubdealerQuantities;
  };

  const findSubdealerWithMostQty = (SubdealerQuantities) => {
    let maxQty = 0;
    let SubdealerWithMostQty = null;

    Object.values(SubdealerQuantities).forEach((firm) => {
      if (firm.total_qty > maxQty) {
        maxQty = firm.total_qty;
        SubdealerWithMostQty = firm;
      }
    });

    return SubdealerWithMostQty;
  };
  const SubdealerQuantities = calculateTotalQuantityBySubdealer(filteredOrders);

  const SubdealerWithMostQty = findSubdealerWithMostQty(SubdealerQuantities);

  const graphDataSubdealer = Object.values(SubdealerQuantities).map((firm) => ({
    name: firm.firm_name,
    quantity: firm.total_qty,
  }));
  const getTopSubdealer = (SubdealerQuantities) => {
    const firmsArray = Object.values(SubdealerQuantities);

    // Sort by total quantity in descending order and take the top 10
    const topSubdealer = firmsArray
      .sort((a, b) => b.total_qty - a.total_qty)
      .slice(0, 10);

    return topSubdealer;
  };
  const topSubdealer = getTopSubdealer(SubdealerQuantities);

  // Category names mapping
  const categoryNames = {
    5: "Architect",
    6: "Builder",
    7: "Contractor",
    8: "Competitor",
    10: "Other",
  };

  const [categoryFilter, setCategoryFilter] = useState(""); // New state for category filtering

  const getTopFirmsByQuantity = (filteredOrders, categoryFilter) => {
    const excludedCategories = [3, 4];
    const firmQuantities = {};

    filteredOrders.forEach((order) => {
      const { firm_id, firm_name, category_id } = order.order;

      if (categoryFilter && category_id !== categoryFilter) {
        return; // Skip orders not matching the selected category
      }

      if (excludedCategories.includes(parseInt(category_id, 10))) {
        return;
      }

      const categoryName = categoryNames[category_id] || "Unknown";

      if (!firmQuantities[firm_id]) {
        firmQuantities[firm_id] = {
          total_count: 0,
          firm_name,
          category_name: categoryName,
        };
      }

      order.orderHistory.forEach((item) => {
        firmQuantities[firm_id].total_count += parseInt(item.qty, 10);
      });
    });

    const firmArray = Object.values(firmQuantities);
    firmArray.sort((a, b) => b.total_count - a.total_count);

    return firmArray.slice(0, 10);
  };

  const getTopFirmsByPrice = (filteredOrders, categoryFilter) => {
    const excludedCategories = [3, 4];
    const firmSales = {};

    filteredOrders.forEach((order) => {
      const { firm_id, firm_name, category_id } = order.order;

      if (categoryFilter && category_id !== categoryFilter) {
        return;
      }

      if (excludedCategories.includes(parseInt(category_id, 10))) {
        return;
      }

      const categoryName = categoryNames[category_id] || "Unknown";

      if (!firmSales[firm_id]) {
        firmSales[firm_id] = {
          total_sales: 0,
          firm_name,
          category_name: categoryName,
        };
      }

      order.orderHistory.forEach((item) => {
        firmSales[firm_id].total_sales +=
          parseFloat(item.price) * parseInt(item.qty, 10);
      });
    });

    const firmArray = Object.values(firmSales);
    firmArray.sort((a, b) => b.total_sales - a.total_sales);

    return firmArray.slice(0, 10);
  };

  const topFirms =
    filter === "quantity"
      ? getTopFirmsByQuantity(filteredOrders, categoryFilter)
      : getTopFirmsByPrice(filteredOrders, categoryFilter);

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FFCD56",
    "#36A3EB",
    "#FF6385",
    "#9967FF",
  ];
  return (
    <div style={{ background: "linear-gradient(to right, #ffedff, #fff)" }}>
      <Topbar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "linear-gradient(to right, #9a43a2, #fde0ff)",
          padding: "10px",
          margin: "30px",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h4" sx={{ color: "white" }}>
          Reports
        </Typography>
        <div>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: "10px" }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>

      <Grid
        container
        spacing={3}
        style={{ paddingInline: "30px", marginBottom: "30px" }}
      >
        <Grid item xs={6}>
          <Card style={{ backgroundColor: "#f8ee1f" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">
                  Top Product:{" "}
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                    {topProducts[0] ? topProducts[0].product_name : "N/A"}
                  </span>
                </Typography>
                <Typography variant="h2">
                  {topProducts[0] ? ` ${topProducts[0].total_qty}` : "N/A"}
                </Typography>
              </div>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ marginBottom: "20px" }}
              >
                <BarChart data={graphData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="quantity" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
              <TopProductsCard topProducts={topProducts} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ backgroundColor: "#f9bcff" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">
                  Top Salesman:{" "}
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                    {firmName}
                  </span>
                </Typography>
                <Typography variant="h2">{count}</Typography>
              </div>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ marginBottom: "20px" }}
              >
                <BarChart data={graphData2}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="quantity" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
              <TopSalesCard topSales={topSales} />
            </CardContent>
          </Card>
        </Grid>{" "}
        <Grid item xs={6}>
          <Card style={{ backgroundColor: "#5fff90" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">
                  Top Dealer:{" "}
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                    {firmWithMostQty?.firm_name}
                  </span>
                </Typography>
                <Typography variant="h2">
                  {firmWithMostQty?.total_qty}
                </Typography>
              </div>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ marginBottom: "20px" }}
              >
                <BarChart data={graphDataFirm}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="quantity" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
              <TopDealerCard topDealers={topDealers} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ backgroundColor: "#ffa9a9" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">
                  Top Subealer:{" "}
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                    {SubdealerWithMostQty?.firm_name}
                  </span>
                </Typography>
                <Typography variant="h2">
                  {SubdealerWithMostQty?.total_qty}
                </Typography>
              </div>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ marginBottom: "20px" }}
              >
                <BarChart data={graphDataSubdealer}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="quantity" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
              <TopSubDealerCard topSubdealer={topSubdealer} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: "200px", marginRight: "20px" }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
              label="Category"
            >
              <MenuItem value="">All Categories</MenuItem>{" "}
              {/* For showing all categories */}
              {Object.keys(categoryNames).map((categoryId) => (
                <MenuItem key={categoryId} value={categoryId}>
                  {categoryNames[categoryId]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Display Mode</InputLabel>
            <Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              label="Display Mode"
            >
              <MenuItem value="quantity">
                Top 10 Clients by Order Quantity
              </MenuItem>
              <MenuItem value="price">Top 10 Clients by Total Sales</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                Top 10 Clients (Based on{" "}
                {filter === "quantity" ? "Order Quantity" : "Total Sales"}) in{" "}
                {categoryFilter
                  ? categoryNames[categoryFilter]
                  : "All Categories"}
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={topFirms}
                    dataKey={
                      filter === "quantity" ? "total_count" : "total_sales"
                    }
                    nameKey="firm_name"
                    innerRadius={60}
                    outerRadius={90}
                    fill="#8884d8"
                    label={(entry) =>
                      `${entry.firm_name} (${entry.category_name}): ${
                        entry[
                          filter === "quantity" ? "total_count" : "total_sales"
                        ]
                      }`
                    }
                  >
                    {topFirms.map((firm, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
