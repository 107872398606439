import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewLeave = createAsyncThunk(
  "ViewLeave/fetchViewLeave",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/leave`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewLeaveSlice = createSlice({
  name: "ViewLeave",
  initialState: {
    ViewLeaveData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewLeave.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewLeave.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewLeaveData = action.payload;
      //   console.log("Viewcattt data:", action.payload);
    });
    builder.addCase(fetchViewLeave.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewLeaveSlice.reducer;
