import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TopSubDealerCard = ({ topSubdealer }) => (
  <>
    <Typography variant="h6">Top 10 Dealer</Typography>

    <TableContainer component={Paper}>
      <Table aria-label="top salesmen table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
              Rank
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
              Dealer Name
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
              Orders
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topSubdealer?.map((dealer, index) => (
            <StyledTableRow key={dealer.firm_name}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{dealer.firm_name}</StyledTableCell>
              <StyledTableCell>{dealer.total_qty}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
export default TopSubDealerCard;
