import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import VisibilityIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../Assets/images/pin2.png";
import Topbar from "../global/Topbar";
import { fetchViewAttandance } from "../../Redux/Slice/Attandance/ViewAttandanceSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Attandance() {
  const dispatch = useDispatch();
  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [30, 35], // Adjust the size as needed
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
  const AttandanceData = useSelector(
    (state) => state.ViewAttandance.ViewAttandanceData?.data
  );

  const loading = useSelector((state) => state.ViewAttandance.isLoader);
  const error = useSelector((state) => state.ViewAttandance.isError);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  React.useEffect(() => {
    dispatch(fetchViewAttandance());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFromDateChange = (event) => {
    setFromDate(new Date(event.target.value));
  };

  const handleToDateChange = (event) => {
    setToDate(new Date(event.target.value));
  };
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const filteredData = AttandanceData?.filter(
    (attData) =>
      attData.attendance.empCode?.toLowerCase().includes(searchTerm) ||
      (attData.user.name?.toLowerCase().includes(searchTerm) &&
        (!fromDate ||
          new Date(attData.attendance?.attendance_date) >= fromDate) &&
        (!toDate || new Date(attData.attendance?.attendance_date) <= toDate))
  );

  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  //   map
  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (AttandanceData) => {
    const latitude = parseFloat(AttandanceData.attendance?.latitude_clock_in);
    const longitude = parseFloat(AttandanceData.attendance?.longitude_clock_in);

    // Check if latitude and longitude are available
    if (isNaN(latitude) || isNaN(longitude)) {
      // Handle the case where latitude and/or longitude are not available
      toast.error("Latitude and/or Longitude not available for this User.");
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };

  //   map
  const [openMapModal2, setOpenMapModal2] = React.useState(false);
  const [mapLocation2, setMapLocation2] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal2 = (AttandanceData) => {
    const latitude = parseFloat(AttandanceData.attendance?.latitude_clock_out);
    const longitude = parseFloat(
      AttandanceData.attendance?.longitude_clock_out
    );

    // Check if latitude and longitude are available
    if (isNaN(latitude) || isNaN(longitude)) {
      // Handle the case where latitude and/or longitude are not available
      toast.error("Latitude and/or Longitude not available for this User.");
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal2 = () => {
    setOpenMapModal2(false);
  };
  const calculateWorkingHours = (clockIn, clockOut) => {
    if (!clockIn || !clockOut) return "-"; // Return a placeholder if either clock in or clock out time is missing

    // Split the time strings into hours, minutes, and seconds
    const [clockInHours, clockInMinutes, clockInSeconds] = clockIn
      .split(":")
      .map(Number);
    const [clockOutHours, clockOutMinutes, clockOutSeconds] = clockOut
      .split(":")
      .map(Number);

    // Calculate the total seconds for clock in and clock out
    const clockInTotalSeconds =
      clockInHours * 3600 + clockInMinutes * 60 + clockInSeconds;
    const clockOutTotalSeconds =
      clockOutHours * 3600 + clockOutMinutes * 60 + clockOutSeconds;

    // Calculate the difference in seconds
    const diffSeconds = clockOutTotalSeconds - clockInTotalSeconds;

    // Convert the difference back to hours, minutes, and seconds
    const hours = Math.floor(diffSeconds / 3600);
    const minutes = Math.floor((diffSeconds % 3600) / 60);
    const seconds = diffSeconds % 60;

    // Format the result as HH:MM:SS
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Attandance Details</h4>

          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="date"
              label="Date From"
              onChange={handleFromDateChange}
              style={{
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "8px",
              }}
            />
            <input
              type="date"
              label="Date To"
              onChange={handleToDateChange}
              style={{
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "8px",
              }}
            />
            <InputBase
              sx={{ ml: 2, width: "220px" }}
              placeholder="Search Emp Code or Name"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
              }}
            />
          </div>
        </div>

        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : AttandanceData && AttandanceData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Date
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Clock In
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Clock Out
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Working Hours
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((AttandanceData, index) => (
                    <StyledTableRow key={AttandanceData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>
                        {AttandanceData.attendance?.empCode}
                      </StyledTableCell>{" "}
                      <StyledTableCell>
                        {AttandanceData.user?.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {AttandanceData.attendance?.attendance_date
                          .split("-")
                          .reverse()
                          .join("-")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {AttandanceData.attendance?.clock_in}
                        {AttandanceData.attendance?.latitude_clock_in &&
                        AttandanceData.attendance?.longitude_clock_in ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleOpenMapModal(AttandanceData)}
                          >
                            <MapIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell>
                        {AttandanceData.attendance?.clock_out}{" "}
                        {AttandanceData.attendance?.latitude_clock_out &&
                        AttandanceData.attendance?.longitude_clock_out ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleOpenMapModal2(AttandanceData)}
                          >
                            <MapIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>{" "}
                      <StyledTableCell>
                        {calculateWorkingHours(
                          AttandanceData.attendance?.clock_in,
                          AttandanceData.attendance?.clock_out
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, AttandanceData.length]}
              component="div"
              count={AttandanceData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No User available</div>
        )}
      </div>{" "}
      <Dialog open={openMapModal} onClose={handleCloseMapModal}>
        <DialogTitle>Location on Map</DialogTitle>
        <DialogContent>
          <MapContainer
            center={[mapLocation.latitude, mapLocation.longitude]}
            zoom={13}
            style={{ height: "400px", width: "400px" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[mapLocation.latitude, mapLocation.longitude]}
              icon={locationOnIcon}
            >
              {/* <Popup>
                Location: {mapLocation.latitude}, {mapLocation.longitude}
              </Popup> */}
            </Marker>
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMapModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>{" "}
      <Dialog open={openMapModal2} onClose={handleCloseMapModal2}>
        <DialogTitle>Location on Map</DialogTitle>
        <DialogContent>
          <MapContainer
            center={[mapLocation2.latitude, mapLocation2.longitude]}
            zoom={13}
            style={{ height: "400px", width: "400px" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[mapLocation2.latitude, mapLocation2.longitude]}
              icon={locationOnIcon}
            >
              {/* <Popup>
                Location: {mapLocation.latitude}, {mapLocation.longitude}
              </Popup> */}
            </Marker>
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMapModal2} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
