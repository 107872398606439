import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewAdminProductSubcategory = createAsyncThunk(
  "ViewAdminProductSubcategory/fetchViewAdminProductSubcategory",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/admin/sub-category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewAdminProductSubcategorySlice = createSlice({
  name: "ViewAdminProductSubcategory",
  initialState: {
    ViewAdminProductSubcategoryData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewAdminProductSubcategory.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(
      fetchViewAdminProductSubcategory.fulfilled,
      (state, action) => {
        state.isLoader = false;
        state.ViewAdminProductSubcategoryData = action.payload;
        //   console.log("Viewcattt data:", action.payload);
      }
    );
    builder.addCase(fetchViewAdminProductSubcategory.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewAdminProductSubcategorySlice.reducer;
