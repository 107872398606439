import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../Assets/images/pin.png";
import Topbar from "../global/Topbar";
import { fetchViewClient } from "../../Redux/Slice/Client/ViewClientSlice";
import { addClient } from "../../Redux/Slice/Client/AddClientSlice";
import { UpdateClient } from "../../Redux/Slice/Client/UpdateClientSaga";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Contractor() {
  const dispatch = useDispatch();
  const ClientData = useSelector((state) => {
    const originalData = state.ViewClient.ViewClientData?.data;

    const filteredData = originalData?.filter(
      (client) => client.categoery_id === "7"
    );

    return filteredData;
  });
  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30], // Adjust the size as needed
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const loading = useSelector((state) => state.ViewClient.isLoader);
  const error = useSelector((state) => state.ViewClient.isError);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);

  // view list
  React.useEffect(() => {
    dispatch(fetchViewClient());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // add Contractor
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formData, setFormData] = useState({
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
    categoery_id: "7",
    device_time: "",
  });
  const validateForm = () => {
    const errors = {};

    if (!formData.clientName.trim()) {
      errors.clientName = "Firm Name is required";
    }
    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }
    if (!formData.concered_person.trim()) {
      errors.concered_person = "Concerned Person is required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone Number is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    if (!formData.designation.trim()) {
      errors.designation = "Designation is required";
    }

    return errors;
  };
  const [formErrors, setFormErrors] = useState({
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
    device_time: "",
  });
  const handleForm = () => {
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formDataWithDefaults = {
      ...formData,
      categoery_id: "7",
    };

    // Log the form data before dispatching
    console.log("Form Data:", formDataWithDefaults);

    dispatch(addClient(formDataWithDefaults))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          clientName: "",
          address: "",
          concered_person: "",
          phone: "",
          email: "",
          designation: "",
          device_time: "",
        });
        setFormErrors({});

        // Reload data after successful addition
        dispatch(fetchViewClient());

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Contractor:", error);
        toast.error("Error adding Contractor. Please try again.");
      });
  };

  const handleChange = (e) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      device_time: formattedDate,
    });
  };

  //   update
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState({
    id: "",
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
  });

  const handleEditModalChange = (field, value) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
      device_time: formattedDate, // Include device_time in the state update
    }));
  };

  const handleEdit = () => {
    const {
      id,
      clientName,
      address,
      concered_person,
      phone,
      email,
      designation,
    } = editData;

    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const credentials = {
      id,
      clientName,
      address,
      concered_person,
      phone,
      email,
      designation,
      categoery_id: "7",
      device_time: formattedDate, // Use the PC's device time
    };

    dispatch(UpdateClient(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewClient());

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Contractor:", error);
        toast.error("Error updating Contractor. Please try again.");
      });
  };

  const handleOpenEditModal = (ClientData) => {
    if (ClientData) {
      setSelectedData(ClientData);
      setEditData({
        id: ClientData.id,
        clientName: ClientData.clientName,
        address: ClientData.address,
        concered_person: ClientData.concered_person,
        phone: ClientData.phone,
        email: ClientData.email,
        designation: ClientData.designation,
      });
    } else {
      setEditData({
        id: "",
        clientName: "",
        address: "",
        concered_person: "",
        phone: "",
        email: "",
        designation: "",
        categoery_id: "7",
        device_time: "",
      });
    }
    setOpenEditModal(true);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = ClientData?.filter(
    (ClientData) =>
      ClientData.clientName.toLowerCase().includes(searchTerm) ||
      ClientData.concered_person.toLowerCase().includes(searchTerm)
  );

  // Filter the data further based on role_id
  const filteredDataWithRole = filteredData?.filter(
    (ClientData) => ClientData.categoery_id === "7"
  );

  // Slice the data for pagination
  const slicedData = filteredDataWithRole?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (ClientData) => {
    const latitude = parseFloat(ClientData.latitude);
    const longitude = parseFloat(ClientData.longitude);

    // Check if latitude and longitude are available
    if (isNaN(latitude) || isNaN(longitude)) {
      // Handle the case where latitude and/or longitude are not available
      toast.error(
        "Latitude and/or Longitude not available for this Contractor."
      );
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };

  // all map
  const [openMapModalAll, setOpenMapModalAll] = React.useState(false);
  const [mapLocations, setMapLocations] = React.useState([]);

  const handleOpenMapModalAll = () => {
    // Filter the data by category_id === "7" and filter out invalid coordinates
    const filteredMapLocations = ClientData?.filter(
      (client) => client.categoery_id === "7"
    )
      .map((client) => ({
        latitude: parseFloat(client.latitude),
        longitude: parseFloat(client.longitude),
      }))
      .filter(
        (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
      );

    setMapLocations(filteredMapLocations);
    setOpenMapModalAll(true);
  };

  const handleCloseMapModalAll = () => {
    setOpenMapModalAll(false);
  };

  const validMapLocations = mapLocations.filter(
    (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
  );

  let mapBounds = null;
  if (validMapLocations.length > 0) {
    const latLngs = validMapLocations.map((location) =>
      L.latLng(location.latitude, location.longitude)
    );
    mapBounds = L.latLngBounds(latLngs);
  }

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Contractor
          </Button>
          <h4 style={{ color: "#ffffff" }}>Contractor Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => handleOpenMapModalAll()}
              sx={{ color: "#fff" }}
            >
              <MapIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 2, width: "220px" }}
              placeholder="Search by Firm or Concerned Person"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
              }}
            />
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : filteredDataWithRole && filteredDataWithRole.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Address
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Designation
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Geotagged
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((ClientData, index) => (
                    <StyledTableRow key={ClientData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>{ClientData.clientName}</StyledTableCell>
                      <StyledTableCell>
                        {ClientData.concered_person}
                      </StyledTableCell>
                      <StyledTableCell>{ClientData.address}</StyledTableCell>

                      <StyledTableCell>{ClientData.email}</StyledTableCell>
                      <StyledTableCell>{ClientData.phone}</StyledTableCell>
                      <StyledTableCell>
                        {ClientData.designation}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ClientData.latitude && ClientData.longitude
                          ? "Yes"
                          : "No"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(ClientData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        {ClientData.latitude && ClientData.longitude ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleOpenMapModal(ClientData)}
                          >
                            <MapIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, -1]}
              component="div"
              count={filteredDataWithRole.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Contractor available</div>
        )}
        <Dialog open={openModal} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Add Contractor
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Firm Name</h5>
                    <TextField
                      label="Firm Name"
                      fullWidth
                      margin="normal"
                      name="clientName"
                      id="clientName"
                      value={formData.clientName}
                      onChange={handleChange}
                      error={!!formErrors.clientName}
                      helperText={formErrors.clientName}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <h5>Concerned Person</h5>
                    <TextField
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      name="concered_person"
                      id="concered_person"
                      value={formData.concered_person}
                      onChange={handleChange}
                      error={!!formErrors.concered_person}
                      helperText={formErrors.concered_person}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Phone</h5>
                    <TextField
                      label="Phone"
                      fullWidth
                      margin="normal"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      error={!!formErrors.phone}
                      helperText={formErrors.phone}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Email</h5>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Designation</h5>
                    <TextField
                      label="Designation"
                      fullWidth
                      margin="normal"
                      name="designation"
                      id="designation"
                      value={formData.designation}
                      onChange={handleChange}
                      error={!!formErrors.designation}
                      helperText={formErrors.designation}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Address</h5>
                    <TextField
                      label="Address"
                      fullWidth
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      margin="normal"
                      name="address"
                      id="address"
                      value={formData.address}
                      onChange={handleChange}
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>

          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleForm}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEditModal} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Update Contractor
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    id="eid"
                    value={selectedData ? selectedData.id : ""}
                  />
                  <Grid item xs={6}>
                    <h5>Firm Name</h5>
                    <TextField
                      id="clientName"
                      label="Firm Name"
                      defaultValue={selectedData ? selectedData.clientName : ""}
                      onChange={(e) =>
                        handleEditModalChange("clientName", e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      error={!!formErrors.clientName}
                      helperText={formErrors.clientName}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h5>Concerned Person</h5>
                    <TextField
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      name="concered_person"
                      id="concered_person"
                      defaultValue={
                        selectedData ? selectedData.concered_person : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("concered_person", e.target.value)
                      }
                      error={!!formErrors.concered_person}
                      helperText={formErrors.concered_person}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Phone</h5>
                    <TextField
                      label="Phone"
                      fullWidth
                      margin="normal"
                      name="phone"
                      id="phone"
                      defaultValue={selectedData ? selectedData.phone : ""}
                      onChange={(e) =>
                        handleEditModalChange("phone", e.target.value)
                      }
                      error={!!formErrors.phone}
                      helperText={formErrors.phone}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Email</h5>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      name="email"
                      id="email"
                      defaultValue={selectedData ? selectedData.email : ""}
                      onChange={(e) =>
                        handleEditModalChange("email", e.target.value)
                      }
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Designation</h5>
                    <TextField
                      label="Designation"
                      fullWidth
                      margin="normal"
                      name="designation"
                      id="designation"
                      defaultValue={
                        selectedData ? selectedData.designation : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("designation", e.target.value)
                      }
                      error={!!formErrors.designation}
                      helperText={formErrors.designation}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Address</h5>
                    <TextField
                      label="Address"
                      fullWidth
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      margin="normal"
                      name="address"
                      id="address"
                      defaultValue={selectedData ? selectedData.address : ""}
                      onChange={(e) =>
                        handleEditModalChange("address", e.target.value)
                      }
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>
          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseEditModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEdit}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModal} onClose={handleCloseMapModal}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              center={[mapLocation.latitude, mapLocation.longitude]}
              zoom={13}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapLocation.latitude, mapLocation.longitude]}
                icon={locationOnIcon}
              >
                {/* <Popup>
                Location: {mapLocation.latitude}, {mapLocation.longitude}
              </Popup> */}
              </Marker>
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModal} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModalAll} onClose={handleCloseMapModalAll}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              bounds={mapBounds}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {ClientData?.map((client) => {
                const latitude = parseFloat(client.latitude);
                const longitude = parseFloat(client.longitude);
                if (isNaN(latitude) || isNaN(longitude)) {
                  // Skip markers with invalid latitude or longitude
                  return null;
                }
                return (
                  <Marker
                    key={client.id}
                    position={[latitude, longitude]}
                    icon={locationOnIcon}
                  >
                    <Popup>{client.clientName}</Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModalAll} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
