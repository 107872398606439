import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewRole = createAsyncThunk(
  "ViewRole/fetchViewRole",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/admin/role`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewRoleSlice = createSlice({
  name: "ViewRole",
  initialState: {
    ViewRoleData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewRole.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewRole.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewRoleData = action.payload;
      // console.log("ViewRole data:", action.payload);
    });
    builder.addCase(fetchViewRole.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewRoleSlice.reducer;
