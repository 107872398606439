import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const TopProductsCard = ({ topProducts }) => (
  <>
    <Typography variant="h6">Top 10 Products by Quantity</Typography>

    <TableContainer component={Paper}>
      <Table aria-label="customized table" id="exportTable">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
              Rank
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
              Product Id
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
              Product Name
            </StyledTableCell>

            <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
              Total Sales
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topProducts.map((product, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{product.product_id}</StyledTableCell>
              <StyledTableCell>{product.product_name}</StyledTableCell>{" "}
              <StyledTableCell>{product.total_qty}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
export default TopProductsCard;
