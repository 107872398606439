import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import Topbar from "../global/Topbar";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../Redux/Slice/ChangePasswordSlice";

const ChangePassword = () => {
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const handleChange = async () => {
    try {
      const action = await dispatch(
        changePassword({ current_password, new_password, confirm_password })
      );

      if (action.payload.error) {
        setErrorMessage(action.payload.error.message || "Login failed");
        return;
      }

      setErrorMessage("");
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("Incorrect Password");
    }
  };

  return (
    <>
      <Topbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "linear-gradient(to right, #ffedff, #fff)",
        }}
      >
        <form style={formStyle}>
          <input
            type="password"
            placeholder="Current Password"
            value={current_password}
            onChange={(e) => setCurrentPassword(e.target.value)}
            style={inputStyle}
          />
          <input
            type="password"
            placeholder="New Password"
            value={new_password}
            onChange={(e) => setNewPassword(e.target.value)}
            style={inputStyle}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirm_password}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={inputStyle}
          />
          <button type="button" onClick={handleChange} style={buttonStyle}>
            Change Password
          </button>

          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </form>
      </div>
    </>
  );
};
const formStyle = {
  display: "flex",
  flexDirection: "column",
  width: "300px",
  background: "linear-gradient(to right, #ff6eff, #ffe2ff)",
  padding: "20px",
  borderRadius: "8px",
};

const inputStyle = {
  margin: "10px",
  padding: "8px",
  fontSize: "16px",
};

const buttonStyle = {
  margin: "10px",
  padding: "10px",
  fontSize: "18px",
  backgroundColor: "#141b2d",
  color: "white",
  cursor: "pointer",
};
export default ChangePassword;
