import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  DialogActions,
  Button,
  Dialog,
  Popover,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";
import Autocomplete from "@mui/material/Autocomplete";
import { fetchViewAdminDealer } from "../../../Redux/Slice/Admin/Dealer/ViewAdminDealerSlice";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import { fetchViewOrder } from "../../../Redux/Slice/Order/ViewOrderSlice";
import { apiUrl } from "../../../Redux/apiConfig";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SalesOrders() {
  const dispatch = useDispatch();

  const orderData = useSelector((state) => state.ViewOrder.ViewOrderData?.data);
  const loading = useSelector((state) => state.ViewAdminDealer.isLoader);
  const error = useSelector((state) => state.ViewAdminDealer.isError);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  React.useEffect(() => {
    dispatch(fetchViewAdminDealer());
    dispatch(fetchViewUser());
    dispatch(fetchViewOrder());
  }, [dispatch]);

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setSelectedOrderId(order?.order?.id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedOrder(null);
    setModalOpen(false);
    setSelectedOrderId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);

  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };

  const filteredData = orderData?.filter(
    (order) =>
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(order?.order.emp_code)) &&
      (order.order.emp_code?.toLowerCase().includes(searchTerm) ||
        order.order.uuid?.toLowerCase().includes(searchTerm))
  );

  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const getCategoryName = (categoryId) => {
    switch (categoryId) {
      case "3":
        return "Dealer";
      case "4":
        return "Subdealer";
      case "5":
        return "Architect";
      case "6":
        return "Builder";
      case "7":
        return "Contractor";
      case "8":
        return "Competitor";
      case "10":
        return "Other";
      default:
        return "Unknown Category";
    }
  };

  const handleDeleteOrder = async (orderHistoryId, orderId) => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("token");

      // Find the current length of the order history
      const orderHistoryCount = orderHistory.length;

      // If there is only one item, don't allow deletion
      if (orderHistoryCount <= 1) {
        toast.error(
          "You cannot delete all items. At least one item should stay."
        );
        return; // Exit the function
      }

      // Send the DELETE request to remove the order history item
      const response = await fetch(`${apiUrl}/order/delete/${orderHistoryId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Remove the deleted item from the local order history
        const updatedOrderHistory = orderHistory.filter(
          (item) => item.id !== orderHistoryId
        );
        setOrderHistory(updatedOrderHistory);
        setSelectedOrder({
          ...selectedOrder,
          orderHistory: updatedOrderHistory,
        });
        dispatch(fetchViewOrder());

        // Calculate the new total price
        const newTotalPrice = updatedOrderHistory.reduce(
          (total, item) => total + item.qty * item.mrp,
          0
        );

        // Dispatch the updated total price and order ID to the API
        await fetch(`${apiUrl}/order/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: selectedOrderId,
            total_price: newTotalPrice,
          }),
        });

        // Display success message
        toast.success("Order history item deleted successfully");
        dispatch(fetchViewOrder());
      } else {
        // Handle non-successful response
        console.error(
          "Error deleting order history item:",
          response.statusText
        );
        toast.error("Error deleting order history item");
      }
    } catch (error) {
      // Handle fetch error
      console.error("Error deleting order history item:", error);
      toast.error("Error deleting order history item");
    }
  };

  const [orderHistory, setOrderHistory] = useState([]);

  React.useEffect(() => {
    if (selectedOrder) {
      setOrderHistory(selectedOrder.orderHistory); // Initialize orderHistory with the selected order's orderHistory
    }
  }, [selectedOrder]);
  const calculateTotalPrice = (history) => {
    if (!history || !Array.isArray(history)) {
      console.error("Invalid order history:", history); // Add a log for debugging
      return 0; // Default to zero if data is invalid
    }

    return history.reduce((total, item) => {
      const price = item.qty * item.mrp; // Ensure item has `qty` and `mrp`
      return total + price;
    }, 0);
  };

  const updateQuantity = async (orderHistoryId, newQty, order) => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("token");

      // POST the updated quantity to the API endpoint
      const response = await fetch(`${apiUrl}/order/update-qty`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: orderHistoryId,
          qty: newQty,
        }),
      });

      if (response.ok) {
        toast.success("Quantity updated successfully");
        dispatch(fetchViewOrder()); // Refresh the order data
      } else {
        const errorText = await response.text();
        console.error("Error updating quantity:", errorText);
        toast.error("Error updating quantity");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("Error updating quantity");
    }
  };
  const handleQtyChange = async (event, orderHistoryId, orderId) => {
    const newQty = parseInt(event.target.value, 10);

    if (isNaN(newQty) || newQty < 0) {
      toast.error("Invalid quantity");
    } else {
      // Create a copy of the current order history with updated quantity
      const updatedOrderHistory = orderHistory.map((item) => {
        if (item.id === orderHistoryId) {
          return { ...item, qty: newQty };
        }
        return item;
      });
      setOrderHistory(updatedOrderHistory);
      setSelectedOrder({
        ...selectedOrder,
        orderHistory: updatedOrderHistory,
      });
      dispatch(fetchViewOrder());
      // Now recalculate the total price using the updated state
      const newTotalPrice = calculateTotalPrice(updatedOrderHistory); // Pass the updated order history

      // After recalculating, update the API
      await updateQuantity(orderHistoryId, newQty); // Update quantity in API
      await updateOrder(orderId, newTotalPrice); // Update total price in API
    }
  };

  const updateOrder = async (orderId, totalPrice) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${apiUrl}/order/update`, {
        method: "POST", // Verify if POST or PUT is required
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: orderId,
          total_price: totalPrice,
        }),
      });

      if (response.ok) {
        toast.success("Order updated successfully");
      } else {
        const errorMessage = await response.text();
        console.error("Error updating order:", errorMessage);
        toast.error("Error updating order");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Error updating order");
    }
  };
  const calculateTotalPrice2 = () => {
    let totalPrice = 0;

    orderHistory.forEach((item) => {
      const qty = item.qty ?? 0;
      const mrp = item.mrp ?? 0;
      totalPrice += qty * mrp;
    });

    return totalPrice;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedId, setSelectedStatus] = React.useState(null);

  const StatusID = selectedId?.order?.id;

  const handleMenuClick = (event, StatusData) => {
    setAnchorEl(event.currentTarget);
    setSelectedStatus(StatusData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
  };

  const handleMenuItemClick = async (action) => {
    let status;
    if (action === "Accept") {
      status = "Accept";
    } else if (action === "Reject") {
      status = "Rejected";
    }

    const token = localStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in Authorization header
      },
      body: JSON.stringify({ status, id: StatusID }),
    };

    try {
      const response = await fetch(`${apiUrl}/order/update`, requestOptions);
      const data = await response.json();

      if (response.ok) {
        toast.success("Order updated successfully");
      } else {
        console.error("Error updating order:", data.message || data); // Show message if provided
        toast.error("Error updating order");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Error updating order");
    }
    dispatch(fetchViewOrder());
    handleClose();
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString(); // Use the full year
    return `${day}/${month}/${year}`;
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Order Details</h4>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : orderData && orderData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Order Id
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Total Price
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      View
                    </StyledTableCell>{" "}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((OrderData, index) => (
                    <StyledTableRow key={OrderData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>

                      <StyledTableCell>
                        {getCategoryName(OrderData.order?.category_id)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {OrderData.order.firm_name}
                      </StyledTableCell>
                      <StyledTableCell>{OrderData.order.uuid}</StyledTableCell>

                      <StyledTableCell>
                        {OrderData.order.total_price}
                      </StyledTableCell>
                      <StyledTableCell>
                        {OrderData.order.status}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button onClick={() => handleOpenModal(OrderData)}>
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Order available</div>
        )}
        <Dialog open={modalOpen} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Order Details
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                padding: "20px",
              }}
            >
              <DialogContent>
                {selectedOrder && (
                  <React.Fragment>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1, paddingRight: "10px" }}>
                        <p>
                          <strong>Order ID:</strong> {selectedOrder.order.uuid}
                        </p>
                        <p>
                          <strong>Emp Code:</strong>{" "}
                          {selectedOrder.order.emp_code}
                        </p>
                        <p>
                          <strong>Emp Name:</strong> {selectedOrder.user.name}
                        </p>

                        <p>
                          <strong>Category:</strong>{" "}
                          {getCategoryName(selectedOrder.order?.category_id)}
                        </p>
                      </div>
                      <div style={{ flex: 1, paddingLeft: "10px" }}>
                        <p>
                          <strong>Firm Name:</strong>{" "}
                          {selectedOrder.order.firm_name}
                        </p>
                        <p>
                          <strong>Status:</strong> {selectedOrder.order.status}
                        </p>
                        <p>
                          <strong>Date:</strong>{" "}
                          {formatDate(selectedOrder.order.created_at)}
                        </p>
                        <p>
                          <strong>Time:</strong>{" "}
                          {formatTime(selectedOrder.order.created_at)}
                        </p>
                      </div>
                    </div>

                    <TableContainer component={Paper}>
                      <Table aria-label="customized table" id="exportTable">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{ backgroundColor: "#7b0087" }}
                            >
                              Sl No.
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ backgroundColor: "#7b0087" }}
                            >
                              Product Description
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ backgroundColor: "#7b0087" }}
                            >
                              Quantity
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ backgroundColor: "#7b0087" }}
                            >
                              MRP
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ backgroundColor: "#7b0087" }}
                            >
                              Total
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedOrder.orderHistory.map(
                            (historyItem, historyIndex) => (
                              <StyledTableRow key={historyItem.id}>
                                <StyledTableCell>
                                  {historyIndex + 1}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {historyItem.name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {historyItem.qty}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {historyItem.mrp}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {historyItem.mrp * historyItem.qty}
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </React.Fragment>
                )}

                {selectedOrder && selectedOrder.orderHistory ? (
                  <>
                    <div>
                      <p style={{ fontSize: "20px", marginTop: "30px" }}>
                        <strong>Total Price:</strong> {calculateTotalPrice2()}
                      </p>
                    </div>
                  </>
                ) : (
                  <p>Order details not available.</p>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseModal}
                  color="secondary"
                  style={{
                    background: "linear-gradient(to right, #2d0031, #4d0054)",
                    height: "100%",
                    color: "white",
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
