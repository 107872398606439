import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../Redux/Slice/ForgorPasswordSlice";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const handleGetOtp = async () => {
    try {
      const action = await dispatch(forgotPassword({ email }));

      if (action.payload.error) {
        setErrorMessage(action.payload.error.message || "Failed");
        return;
      }

      setErrorMessage("");
      navigate("/login");
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("Incorrect Email");
    }
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ color: "#fff" }}>Forgot Password</h1>
      <form style={formStyle}>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />

        <button type="button" onClick={handleGetOtp} style={buttonStyle}>
          Get Otp
        </button>

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </form>
    </div>
  );
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  background: "url('https://wallpapercave.com/wp/wp2015619.jpg')",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
  color: "white",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  width: "300px",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "20px",
  borderRadius: "8px",
};

const inputStyle = {
  margin: "10px",
  padding: "8px",
  fontSize: "16px",
};

const buttonStyle = {
  margin: "10px",
  padding: "10px",
  fontSize: "18px",
  backgroundColor: "#141b2d",
  color: "white",
  cursor: "pointer",
};

export default ForgetPassword;
