import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import VisibilityIcon from "@mui/icons-material/Edit";
import ExcelDownload from "../../Excel/ExcelDownload";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Paper,
  Button,
  MenuItem,
  Select,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import Topbar from "../../global/Topbar";
import { addUser } from "../../../Redux/Slice/Users/AddUserSlice";
import { fetchViewRole } from "../../../Redux/Slice/Users/ViewRoleSlice";
import { fetchViewTerritory } from "../../../Redux/Slice/Users/ViewTerritorrySlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Users() {
  const dispatch = useDispatch();
  const EmpData = useSelector((state) => state.ViewUser.ViewUserData?.data);
  const TerritoryData = useSelector(
    (state) => state.ViewTerritory.ViewTerritoryData?.data
  );

  const RoleData = useSelector((state) => state.ViewRole.ViewRoleData?.data);
  const loading = useSelector((state) => state.ViewUser.isLoader);
  const error = useSelector((state) => state.ViewUser.isError);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showAllUsers, setShowAllUsers] = React.useState(false); // State to toggle displaying all users
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();
  const [openModal, setOpenModal] = React.useState(false);
  // view list
  React.useEffect(() => {
    dispatch(fetchViewUser());
    dispatch(fetchViewRole());
    dispatch(fetchViewTerritory());
  }, [dispatch]);

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0); // Update the page to the first one when the search term changes
  };

  const toggleUsersDisplay = () => {
    setShowAllUsers(!showAllUsers);
    if (!showAllUsers) {
      setSearchTerm(""); // Clear search term when switching to show all users
    }
  };

  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };

  const filteredData = EmpData?.filter(
    (empData) =>
      (showAllUsers || empData.is_active === "1") &&
      (empData.name.toLowerCase().includes(searchTerm) ||
        empData.empCode.toLowerCase().includes(searchTerm))
  );

  // Slice the data for pagination
  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // add user================================
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [formData, setFormData] = useState({
    role_id: "",
    empCode: "",
    name: "",
    state: "",
    territory: "",
    email: "",
    phone: "",
    state: "Assam",
    dynamic_role: "", // Include dynamic_role field in formData
  });

  const handleTerritoryChange = (event, newValue) => {
    // Ensure newValue is not null or undefined
    if (newValue) {
      // Extract the names of the selected territories
      const selectedTerritories = newValue.map((option) => option.name);
      // Join the names with commas to create a comma-separated string
      const formattedTerritories = selectedTerritories.join(", ");
      // Update the formData with the selected territories
      setFormData({
        ...formData,
        territory: formattedTerritories,
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "User Name is required";
    }

    return errors;
  };

  const [formErrors, setFormErrors] = useState({
    role_id: "",
    empCode: "",
    name: "",
    state: "",
    territory: "",
    email: "",
    phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleForm = () => {
    // If form submission is already in progress, prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Set form submission in progress
    setIsSubmitting(true);

    const formDataWithDefaults = {
      ...formData,
      dynamic_role: Object.values(dynamicFields).join(", "), // Join dynamic_fields into a comma-separated string
    };

    dispatch(addUser(formDataWithDefaults))
      .then(() => {
        setFormData({
          role_id: "",
          empCode: "",
          name: "",
          state: "",
          territory: "",
          email: "",
          phone: "",

          dynamic_role: "", // Clear dynamic_role after successful submission if needed
        });
        setFormErrors({});
        setDynamicFields({});
        dispatch(fetchViewUser());
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding User:", error);
        toast.error("Error adding User. Please try again.");
      })
      .finally(() => {
        // Reset form submission status
        setIsSubmitting(false);
      });
  };

  const handleChange = (e) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      device_time: formattedDate,
    });
  };

  const [dynamicFields, setDynamicFields] = useState({});

  const handleDynamicFieldChange = (e) => {
    const { name, value } = e.target;
    setDynamicFields({
      ...dynamicFields,
      [name]: value,
    });
  };

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {" "}
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add User
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            // startIcon={<AddIcon />}
            onClick={donloadexcel}
          >
            Download Excel
          </Button>
        </div>
        <Paper
          elevation={3}
          style={{
            marginTop: "20px",
            padding: "10px",
            background: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <h2 style={{ margin: "0" }}>User List</h2>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              style={{ background: "white", padding: "10px", borderRadius: 5 }}
              value={searchTerm}
              onChange={handleSearch}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={toggleUsersDisplay}
            >
              {showAllUsers ? "Show Active Users" : "Show All Users"}
            </Button>
          </div>

          <TableContainer>
            <Table id="exportTable" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>EmpCode</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Phone</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      Error fetching data.
                    </TableCell>
                  </TableRow>
                ) : slicedData && slicedData.length > 0 ? (
                  slicedData.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.empCode}
                      </StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>{row.phone}</StyledTableCell>
                      <StyledTableCell>
                        <IconButton>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton>
                          <CancelIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No users found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      {/* Add User Modal */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="EmpCode"
                fullWidth
                margin="normal"
                name="empCode"
                value={formData.empCode}
                onChange={handleChange}
                error={!!formErrors.empCode}
                helperText={formErrors.empCode}
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="User Name"
                fullWidth
                margin="normal"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone"
                fullWidth
                margin="normal"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="role_id">Role</InputLabel>
                <Select
                  label="Role"
                  fullWidth
                  value={formData.role_id}
                  onChange={handleChange}
                  inputProps={{
                    name: "role_id",
                    id: "role_id",
                  }}
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                >
                  {RoleData &&
                    RoleData.length > 0 &&
                    RoleData.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  multiple
                  id="territory"
                  options={TerritoryData || []}
                  getOptionLabel={(option) => option.name}
                  onChange={handleTerritoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Territory"
                      margin="normal"
                      name="territory"
                      value={formData.territory}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {RoleData &&
              RoleData.length > 0 &&
              RoleData.map((role) => (
                <Grid item xs={6} key={role.id}>
                  <h5>{role.name}</h5>
                  <TextField
                    label={role.name}
                    fullWidth
                    margin="normal"
                    name={role.name}
                    value={dynamicFields[role.name] || ""}
                    onChange={handleDynamicFieldChange}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleForm} color="primary" disabled={isSubmitting}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
}
