import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewOrder = createAsyncThunk(
  "ViewOrder/fetchViewOrder",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/order`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewOrderSlice = createSlice({
  name: "ViewOrder",
  initialState: {
    ViewOrderData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewOrder.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewOrder.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewOrderData = action.payload;
      // console.log("ViewOrder data:", action.payload);
    });
    builder.addCase(fetchViewOrder.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewOrderSlice.reducer;
