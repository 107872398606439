import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  DialogActions,
  Button,
  Dialog,
  Popover,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Experimental_CssVarsProvider,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";
import Autocomplete from "@mui/material/Autocomplete";
import { fetchViewAdminDealer } from "../../../Redux/Slice/Admin/Dealer/ViewAdminDealerSlice";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import { fetchViewOrder } from "../../../Redux/Slice/Order/ViewOrderSlice";
import { apiUrl } from "../../../Redux/apiConfig";
import { fetchViewExpense } from "../../../Redux/Slice/Expense/ViewExpenseSlice";
import { fetchViewSalesExpense } from "../../../Redux/Slice/Expense/ViewExpenseSalesSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ExpenseSales() {
  const dispatch = useDispatch();
  const expenseData = useSelector(
    (state) => state.ViewSalesExpense.ViewSalesExpenseData?.data
  );
  console.log("expenseData", expenseData);
  const loading = useSelector((state) => state.ViewAdminDealer.isLoader);
  const error = useSelector((state) => state.ViewAdminDealer.isError);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  React.useEffect(() => {
    dispatch(fetchViewAdminDealer());
    dispatch(fetchViewUser());
    dispatch(fetchViewOrder());
    dispatch(fetchViewSalesExpense());
  }, [dispatch]);

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedOrder(null);
    setModalOpen(false);
  };
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  };
  const filteredData = expenseData?.filter((order) => {
    const empCode = order?.main_expense?.empCode?.toLowerCase() || ""; // Handle potential null
    const empName = order?.main_expense?.name?.toLowerCase() || ""; // Handle potential null

    const isEmpCodeMatch =
      selectedEmpCodes.length === 0 || selectedEmpCodes.includes(empCode);
    const isSearchTermInEmpCode = empCode.includes(searchTerm);
    const isSearchTermInEmpName = empName.includes(searchTerm);

    // Apply filter based on selected employee codes and search term
    return isEmpCodeMatch && (isSearchTermInEmpCode || isSearchTermInEmpName);
  });

  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [orderHistory, setOrderHistory] = useState([]);

  React.useEffect(() => {
    if (selectedOrder) {
      setOrderHistory(selectedOrder.orderHistory); // Initialize orderHistory with the selected order's orderHistory
    }
  }, [selectedOrder]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const id = open ? "simple-popover" : undefined;

  const id2 = open2 ? "simple-popover" : undefined;

  const [selectedId, setSelectedStatus] = React.useState(null);
  const [selectedId2, setSelectedStatus2] = React.useState(null);
  const [selectedIdMain, setSelectedStatusMain] = React.useState(null);

  const StatusID = selectedId?.main_expense.id;

  const handleMenuClick = (event, StatusData) => {
    setAnchorEl(event.currentTarget);
    setSelectedStatus(StatusData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
  };
  console.log("selectedOrder", selectedOrder);
  const handleMenuItemClick = async (action) => {
    let main_status;
    if (action === "Accept") {
      main_status = "Accept";
    } else if (action === "Reject") {
      main_status = "Rejected";
    }

    const token = localStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in Authorization header
      },
      body: JSON.stringify({
        main_status,
        main_id: StatusID,
        total: totalPrice,
      }),
    };

    try {
      const response = await fetch(
        `${apiUrl}/admin/expense/update`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        toast.success("Order updated successfully");
      } else {
        console.error("Error updating order:", data.message || data); // Show message if provided
        toast.error("Error updating order");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Error updating order");
    }
    dispatch(fetchViewExpense());
    handleClose();
  };

  const calculateTotalPrice = () => {
    if (!selectedOrder || !selectedOrder.expenses) {
      return 0;
    }

    return selectedOrder.expenses
      .filter((expense) => expense.status !== "Rejected")
      .reduce((total, expense) => {
        const cost = parseFloat(expense.cost) || 0;
        return total + cost;
      }, 0);
  };

  // Update total price when selectedOrder changes
  React.useEffect(() => {
    if (selectedOrder) {
      setTotalPrice(calculateTotalPrice());
    }
  }, [selectedOrder]);

  const handleMenuClick2 = (event, StatusData, main_expense) => {
    setAnchorEl2(event.currentTarget);
    setSelectedStatus2(StatusData);
    setSelectedStatusMain(main_expense);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
    setSelectedStatus2(null);
    setSelectedStatusMain(null);
  };

  const handleRejectReason = async () => {
    const reason = rejectReason; // Get the reason from the input field
    const statusId = selectedId2?.id; // ID of the item being rejected
    const mainStatusId = selectedIdMain?.id; // Main expense ID

    const token = localStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        status: "Rejected",
        main_id: mainStatusId,
        id: statusId,
        reason,
      }),
    };

    try {
      const response = await fetch(
        `${apiUrl}/admin/expense/update`,
        requestOptions
      );
      if (response.ok) {
        // Find the expense in selectedOrder and update its status
        const updatedExpenses = selectedOrder.expenses.map((expense) => {
          if (expense.id === statusId) {
            return { ...expense, status: "Rejected", reason }; // Update the status and reason
          }
          return expense;
        });

        setSelectedOrder((prevOrder) => ({
          ...prevOrder,
          expenses: updatedExpenses, // Update the expenses array
        }));

        toast.success("Order rejected successfully");
        closeRejectReasonModal();
        handleClose2();
      } else {
        toast.error("Error rejecting order");
      }
    } catch (error) {
      toast.error("Error rejecting order");
    }
  };

  const [rejectReason, setRejectReason] = useState(""); // To store the rejection reason
  const [rejectReasonModalOpen, setRejectReasonModalOpen] = useState(false); // Control modal visibility
  const openRejectReasonModal = (statusData) => {
    setSelectedStatus2(statusData);
    setRejectReasonModalOpen(true); // Open the modal
  };
  const closeRejectReasonModal = () => {
    setRejectReason(""); // Reset the rejection reason
    setRejectReasonModalOpen(false); // Close the modal
  };
  const handleMenuItemClick2 = async (action) => {
    if (action === "Reject") {
      openRejectReasonModal(selectedId2); // Open the modal
    }
  };

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Expense Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={Array.from(
                new Set(
                  expenseData
                    ? expenseData
                        .filter(
                          (order) =>
                            order?.main_expense && order?.main_expense?.empCode
                        ) // Filter out clients without user data or empCode
                        .map((order) => order.main_expense?.empCode)
                    : []
                )
              )}
              disableCloseOnSelect
              onChange={handleEmpCodeChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{ height: "33px" }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "6px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    placeholder="Select User"
                    style={{ width: "100px" }}
                  />
                </Paper>
              )}
            /> */}
            {/* <InputBase
              sx={{ ml: 2, width: "150px" }}
              placeholder="Search by Emp Code or Name"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
                marginRight: "10px",
              }}
            /> */}
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : expenseData && expenseData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      From
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      To
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Total Price
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      View
                    </StyledTableCell>{" "}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((OrderData, index) => (
                    <StyledTableRow key={OrderData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>
                        {(() => {
                          const dateStr = OrderData.main_expense.formdate;
                          const parts = dateStr.split("-");
                          const reversedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                          return reversedDate;
                        })()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {(() => {
                          const dateStr = OrderData.main_expense.todate;
                          const parts = dateStr.split("-");
                          const reversedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                          return reversedDate;
                        })()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {OrderData.main_expense.main_status}
                      </StyledTableCell>{" "}
                      <StyledTableCell>
                        {OrderData.main_expense.total}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button onClick={() => handleOpenModal(OrderData)}>
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, expenseData.length]}
              component="div"
              count={expenseData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Order available</div>
        )}
        <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="md">
          <DialogTitle></DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sr.No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Description
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Cost
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Reciept
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Reason
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Image
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedOrder?.expenses?.length > 0 ? (
                    selectedOrder.expenses.map((historyItem, historyIndex) => (
                      <StyledTableRow key={historyItem.id}>
                        <StyledTableCell style={{ width: "20px" }}>
                          {historyIndex + 1}
                        </StyledTableCell>
                        <StyledTableCell>
                          {historyItem.description}
                        </StyledTableCell>
                        <StyledTableCell>{historyItem.cost}</StyledTableCell>
                        <StyledTableCell>
                          {historyItem.receipt}
                        </StyledTableCell>{" "}
                        <StyledTableCell>{historyItem.status}</StyledTableCell>
                        <StyledTableCell>{historyItem.reason}</StyledTableCell>
                        <StyledTableCell>
                          {historyItem.image ? (
                            <img
                              src={historyItem.image}
                              alt={`Image for ${historyItem.description}`}
                              style={{
                                maxWidth: "100px", // Adjust as needed for appropriate size
                                maxHeight: "100px",
                                objectFit: "cover", // Keeps the aspect ratio intact
                              }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={4}
                        style={{ textAlign: "center" }}
                      >
                        No Expenses Found
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <>
              <div>
                <p style={{ fontSize: "20px", marginTop: "30px" }}>
                  <strong>Total Price:</strong> {calculateTotalPrice()}
                </p>
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseModal}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={rejectReasonModalOpen} onClose={closeRejectReasonModal}>
          <DialogTitle>Reason for Rejection</DialogTitle>
          <DialogContent>
            <TextField
              label="Reason"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)} // Update the reason
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeRejectReasonModal}>Cancel</Button>
            <Button
              onClick={handleRejectReason}
              style={{ backgroundColor: "#7b0087", color: "#fff" }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
