import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";
import Autocomplete from "@mui/material/Autocomplete";
import { apiUrl } from "../../../Redux/apiConfig";
import { fetchViewLeave } from "../../../Redux/Slice/Leave/ViewLeaveSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function LeaveSales() {
  const dispatch = useDispatch();

  const LeaveData = useSelector(
    (state) => state.ViewLeave?.ViewLeaveData?.data
  );
  console.log("LeaveData", LeaveData);
  const loading = useSelector((state) => state.ViewAdminDealer.isLoader);
  const error = useSelector((state) => state.ViewAdminDealer.isError);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month

  React.useEffect(() => {
    dispatch(fetchViewLeave());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);

  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const filteredData = LeaveData?.filter(
    (leave) =>
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(leave?.empCode)) &&
      (leave.empCode?.toLowerCase().includes(searchTerm) ||
        leave.user?.toLowerCase().includes(searchTerm)) &&
      new Date(leave.from_date).getMonth() + 1 === selectedMonth // Filter by month
  );

  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedId, setSelectedStatus] = React.useState(null);

  const handleMenuClick = (event, StatusData) => {
    setAnchorEl(event.currentTarget);
    setSelectedStatus(StatusData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
  };
  const StatusID = selectedId?.id;
  const handleMenuItemClick = async (action) => {
    let status;
    if (action === "Accept") {
      status = "Accept";
    } else if (action === "Reject") {
      status = "Rejected";
    }

    const token = localStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in Authorization header
      },
      body: JSON.stringify({ status, id: StatusID }),
    };

    try {
      const response = await fetch(
        `${apiUrl}/admin/attandance/update`,
        requestOptions
      );
      const data = await response.json();

      if (response.ok) {
        toast.success("Order updated successfully");
      } else {
        console.error("Error updating order:", data.message || data); // Show message if provided
        toast.error("Error updating order");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Error updating order");
    }
    dispatch(fetchViewLeave());
    handleClose();
  };
  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Leave Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              style={{
                padding: "7px",
                margin: "0 3px",
                borderRadius: "5px",
                marginLeft: "20px",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : LeaveData && LeaveData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>

                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Leave From
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Leave To
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Days
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Reason
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Status
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((LeaveData, index) => (
                    <StyledTableRow key={LeaveData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>

                      <StyledTableCell>
                        {(() => {
                          const dateStr = LeaveData.from_date;
                          const parts = dateStr.split("-");
                          const reversedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                          return reversedDate;
                        })()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {(() => {
                          const dateStr = LeaveData.to_date;
                          const parts = dateStr.split("-");
                          const reversedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                          return reversedDate;
                        })()}
                      </StyledTableCell>
                      <StyledTableCell>{LeaveData.days}</StyledTableCell>
                      <StyledTableCell>{LeaveData.reason}</StyledTableCell>
                      <StyledTableCell>{LeaveData.status}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Order available</div>
        )}
      </div>
    </>
  );
}
