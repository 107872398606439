import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Paper,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
  Box,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";
import { fetchViewAdminClient } from "../../../Redux/Slice/Admin/Clients/ViewAdminClientsSlice";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import { fetchViewDevice } from "../../../Redux/Slice/Device/ViewDeviceSlice";
import { UpdateDevice } from "../../../Redux/Slice/Device/UpdateDeviceSlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Device() {
  const dispatch = useDispatch();

  const DeviceData = useSelector(
    (state) => state.ViewDevice.ViewDeviceData?.data
  );
  console.log("DeviceData", DeviceData);
  const loading = useSelector((state) => state.ViewAdminClients.isLoader);
  const error = useSelector((state) => state.ViewAdminClients.isError);
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);

  // view list
  React.useEffect(() => {
    dispatch(fetchViewAdminClient());
    dispatch(fetchViewUser());
    dispatch(fetchViewDevice());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   update
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState({
    id: "",
    device_active: "",
  });

  const handleEditModalChange = (field, value) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
      device_time: formattedDate, // Include device_time in the state update
    }));
  };

  const handleEdit = () => {
    const { id, device_active } = editData;

    const credentials = {
      id,
      device_active,
    };

    dispatch(UpdateDevice(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewDevice());

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Role:", error);
        toast.error("Error updating Role. Please try again.");
      });
  };

  const handleOpenEditModal = (DeviceData) => {
    if (DeviceData) {
      setSelectedData(DeviceData);
      setEditData({
        id: DeviceData.id,
        device_active: DeviceData.device_active,
      });
    } else {
      setEditData({
        id: "",
        device_active: "",
      });
    }
    setOpenEditModal(true);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = DeviceData?.filter(
    (role) =>
      ((selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(role?.user?.name)) &&
        role.user?.name.toLowerCase().includes(searchTerm)) ||
      role.user?.empCode.toLowerCase().includes(searchTerm)
  );

  // Slice the data for pagination
  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Device Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputBase
              sx={{ ml: 2, width: "150px" }}
              placeholder="Search Emp Name or Code"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
                marginRight: "10px",
              }}
            />
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : DeviceData && DeviceData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Device Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Device Os Version
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Imei Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((DeviceData, index) => (
                    <StyledTableRow key={DeviceData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>
                        {DeviceData?.user?.empCode}
                      </StyledTableCell>
                      <StyledTableCell>
                        {DeviceData?.user?.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {DeviceData?.device_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {DeviceData?.mobile_version}
                      </StyledTableCell>
                      <StyledTableCell>{DeviceData?.imel_code}</StyledTableCell>
                      <StyledTableCell>
                        {DeviceData.device_active === "1"
                          ? "Active"
                          : "Not Active"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(DeviceData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, DeviceData.length]}
              component="div"
              count={DeviceData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Role available</div>
        )}

        <Dialog open={openEditModal} maxWidth="md">
          <DialogTitle
            sx={{
              background: "linear-gradient(to right, #fff, #bfbfbf)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Update Status</span>
              <IconButton color="secondary" onClick={handleCloseEditModal}>
                <CancelIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    id="eid"
                    value={selectedData ? selectedData.id : ""}
                  />
                  <Grid item xs={12}>
                    <h5>Status *</h5>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="device_active">
                        Select Status
                      </InputLabel>
                      <Select
                        label="Status"
                        name="device_active"
                        id="device_active"
                        value={editData.device_active}
                        onChange={(e) => {
                          setEditData({
                            ...editData,
                            device_active: e.target.value,
                          });
                        }}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        <MenuItem value={1}>Activate</MenuItem>
                        <MenuItem value={2}>Deactivate</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>
          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseEditModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEdit}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
