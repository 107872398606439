import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import VisibilityIcon from "@mui/icons-material/Edit";
import { Close as CloseIcon } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";
import { fetchViewProducts } from "../../../Redux/Slice/Products/ViewProductsSlice";
import AddProductSlice, {
  addAdminProduct,
  addProduct,
} from "../../../Redux/Slice/Products/AddProductSlice";
import { UpdateProduct } from "../../../Redux/Slice/Products/UpdateProductSlice";
import { fetchViewAdminBrand } from "../../../Redux/Slice/Admin/Brand/ViewBrandSlice";
import { fetchViewAdminProductCategory } from "../../../Redux/Slice/Admin/Category/ViewProductCategorySlice";
import { fetchViewAdminProductSubcategory } from "../../../Redux/Slice/Admin/SubCategory/ViewProductSubCategorySlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Products() {
  const dispatch = useDispatch();
  const ProductsData = useSelector(
    (state) => state.ViewProducts.ViewProductsData?.data
  );
  const brandData = useSelector(
    (state) => state.ViewBrand.ViewAdminBrandData?.data
  );
  const CategoryData = useSelector(
    (state) => state.ViewProductCategory.ViewAdminProductCategoryData?.data
  );
  const SubcategoryData = useSelector(
    (state) =>
      state.ViewProductSubCategory.ViewAdminProductSubcategoryData?.data
  );
  const loading = useSelector((state) => state.ViewAdminClients.isLoader);
  const error = useSelector((state) => state.ViewAdminClients.isError);
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openModal, setOpenModal] = React.useState(false);
  // view list
  React.useEffect(() => {
    dispatch(fetchViewProducts());
    dispatch(fetchViewAdminBrand());
    dispatch(fetchViewAdminProductCategory());
    dispatch(fetchViewAdminProductSubcategory());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // add Products
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formData, setFormData] = useState({
    brand: "",
    category: "",
    subcategory: "",
    sku_code: "",
    name: "",
    description: "",
    mrp: "",
    qty: "",
    image: null,
  });
  const validateForm = () => {
    const errors = {};
    // if (!formData.brand?.trim()) {
    //   errors.brand = "Brand Code is required";
    // }
    // if (!formData.category.trim()) {
    //   errors.category = "Category is required";
    // }
    if (!formData.sku_code.trim()) {
      errors.sku_code = "Sku Code is required";
    }
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.mrp.trim()) {
      errors.mrp = "Mrp is required";
    }
    if (!formData.qty.trim()) {
      errors.qty = "Qty is required";
    }

    return errors;
  };
  const [formErrors, setFormErrors] = useState({
    brand: "",
    category: "",
    subcategory: "",
    sku_code: "",
    name: "",
    mrp: "",
    qty: "",
    image: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));

    const imageUrl = URL.createObjectURL(file);
    setPreviewImageUrl(imageUrl);
  };
  const handleForm = () => {
    // If form submission is already in progress, prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Set form submission in progress
    setIsSubmitting(true);

    dispatch(addProduct(formData))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          brand: "",
          category: "",
          subcategory: "",
          sku_code: "",
          name: "",
          description: "",
          mrp: "",
          qty: "",
          image: "",
        });
        setFormErrors({});

        // Reload data after successful addition
        dispatch(fetchViewProducts());

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Products:", error);
        toast.error("Error adding Products. Please try again.");
      })
      .finally(() => {
        // Reset form submission status
        setIsSubmitting(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // update

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editpreviewImageUrl, seteditPreviewImageUrl] = useState(null);
  const [sortColumn, setSortColumn] = useState(null); // Current sorting column
  const [sortOrder, setSortOrder] = useState(null); // Current sorting order
  const [editData, setEditData] = React.useState({
    id: "",
    brand: "",
    category: "",
    subcategory: "",
    sku_code: "",
    name: "",
    description: "",
    mrp: "",
    qty: "",
    image: "",
  });

  const handleEditModalChange = (e) => {
    const { name, value } = e.target;
    setEditData((preveditData) => ({
      ...preveditData,
      [name]: value,
    }));
  };
  const handleEdit = () => {
    const {
      id,
      brand,
      category,
      subcategory,
      sku_code,
      name,
      description,
      mrp,
      qty,
      image,
    } = editData;

    const credentials = {
      id,
      brand,
      category,
      subcategory,
      sku_code,
      name,
      description,
      mrp,
      qty,
      image,
    };

    dispatch(UpdateProduct(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewProducts());

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Products:", error);
        toast.error("Error updating Products. Please try again.");
      });
  };

  const handleOpenEditModal = (ProductsData) => {
    if (ProductsData) {
      setSelectedData(ProductsData);
      setEditData({
        id: ProductsData.id,
        brand: ProductsData.brand_name,
        category: ProductsData.category_name,
        subcategory: ProductsData.subcategory_name,
        sku_code: ProductsData.sku_code,
        name: ProductsData.name,
        description: ProductsData.description,
        mrp: ProductsData.mrp,
        qty: ProductsData.qty,
        image: ProductsData.img,
      });
    } else {
      setEditData({
        id: "",
        brand: "",
        category: "",
        subcategory: "",
        sku_code: "",
        name: "",
        description: "",
        mrp: "",
        qty: "",
        image: "",
      });
    }
    setOpenEditModal(true);
  };

  const handleEditImageChange = (e) => {
    const file = e.target.files[0];
    setEditData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
    const imageUrledit = URL.createObjectURL(file);
    seteditPreviewImageUrl(imageUrledit);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = ProductsData?.filter(
    (client) =>
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(client?.name)) &&
      (client.name.toLowerCase().includes(searchTerm) ||
        client.sku_code.toLowerCase().includes(searchTerm))
  );

  // Slice the data for pagination
  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to open the modal and set the selected image
  const handleOpenImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpenImageModal(true);
  };

  // Function to close the modal and clear the selected image
  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setSelectedImage(null);
  };

  const sortedData = sortColumn
    ? [...(slicedData || [])].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortColumn] - b[sortColumn]; // Ascending
        } else {
          return b[sortColumn] - a[sortColumn]; // Descending
        }
      })
    : slicedData; // No sorting, use original data

  const toggleSortOrder = (column) => {
    if (sortColumn === column) {
      // Toggle the sort order if we're already sorting by this column
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      // If we're sorting by a different column, set it to ascending and change the sort column
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const [selectedCategory, setSelectedCategory] = useState("");

  const filteredSubcategories = SubcategoryData?.filter(
    (sub) => Number(sub.category_id) === Number(selectedCategory)
  );
  return (
    <>
      <Dialog open={openModal} maxWidth="md">
        <DialogTitle
          sx={{
            background: "linear-gradient(to right, #fff, #bfbfbf)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Add Products</span>
            <IconButton color="secondary" onClick={handleCloseModal}>
              <CancelIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <h5>Brand *</h5>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Brand</InputLabel>
                    <Select
                      name="brand"
                      value={formData.brand}
                      onChange={handleChange}
                      error={!!formErrors.brand}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    >
                      {brandData?.map((brand) => (
                        <MenuItem key={brand.id} value={brand.id}>
                          {brand.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.brand && (
                      <p style={{ color: "red", margin: "0.5rem 0" }}>
                        {formErrors.brand}
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <h5>Category *</h5>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      value={formData.category}
                      onChange={(e) => {
                        handleChange(e);
                        setSelectedCategory(e.target.value);
                      }}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      label="Category"
                    >
                      {CategoryData?.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <h5>Subcategory *</h5>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Subcategory</InputLabel>
                    <Select
                      name="subcategory"
                      value={formData.subcategory}
                      onChange={handleChange}
                      label="Subcategory"
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    >
                      {filteredSubcategories?.map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.id}>
                          {subcategory.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <h5>Sku Code *</h5>
                  <TextField
                    label="Sku Code"
                    fullWidth
                    margin="normal"
                    name="sku_code"
                    id="sku_code"
                    value={formData.sku_codev}
                    onChange={handleChange}
                    error={!!formErrors.sku_code}
                    helperText={formErrors.sku_code}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Product Name *</h5>
                  <TextField
                    label="Product Name"
                    fullWidth
                    margin="normal"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Mrp *</h5>
                  <TextField
                    label="Mrp"
                    fullWidth
                    margin="normal"
                    name="mrp"
                    id="mrp"
                    value={formData.mrp}
                    onChange={handleChange}
                    error={!!formErrors.mrp}
                    helperText={formErrors.mrp}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Qty *</h5>
                  <TextField
                    label="qty"
                    fullWidth
                    margin="normal"
                    name="qty"
                    id="qty"
                    value={formData.qty}
                    onChange={handleChange}
                    error={!!formErrors.qty}
                    helperText={formErrors.qty}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <h5>Description </h5>
                  <TextField
                    label="Description"
                    fullWidth
                    multiline
                    rows={4} // Adjust the number of rows as needed
                    margin="normal"
                    name="description"
                    id="description"
                    value={formData.description}
                    onChange={handleChange}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <h5>Image *</h5>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "block", margin: "10px 0" }}
                  />

                  {previewImageUrl && (
                    <img
                      src={previewImageUrl}
                      alt="Selected"
                      style={{ maxWidth: "25%", marginTop: "16px" }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions
          sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
        >
          <Button
            onClick={handleCloseModal}
            color="secondary"
            style={{
              background: "#fff",
              height: "100%",
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleForm}
            style={{
              background: "linear-gradient(to right, #2d0031, #4d0054)",
              height: "100%",
              color: "white",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Products
          </Button>

          <h4 style={{ color: "#ffffff" }}>Products Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={Array.from(
                new Set(
                  ProductsData
                    ? ProductsData.filter((client) => client && client.name) // Filter out clients without user data or empCode
                        .map((client) => client.name)
                    : []
                )
              )} // Provide an empty array if ProductsData is undefined
              disableCloseOnSelect
              onChange={handleEmpCodeChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{ height: "33px" }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "6px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    placeholder="Select Product"
                    style={{ width: "100px" }}
                  />
                </Paper>
              )}
            />
            <InputBase
              sx={{ ml: 2, width: "150px" }}
              placeholder="Search by Sku or Name"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
                marginRight: "10px",
              }}
            />
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : filteredData && filteredData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Brand
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Catgeory
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sub Category
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sku Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Description
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      MRP
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => toggleSortOrder("mrp")}
                          size="small"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <ArrowDropUp
                            style={{
                              color:
                                sortColumn === "mrp" && sortOrder === "asc"
                                  ? "green"
                                  : "gray",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => toggleSortOrder("mrp")}
                          size="small"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <ArrowDropDown
                            style={{
                              color:
                                sortColumn === "mrp" && sortOrder === "desc"
                                  ? "red"
                                  : "gray",
                              position: "relative",
                              bottom: "4px",
                            }}
                          />
                        </IconButton>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Qty
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => toggleSortOrder("qty")}
                          size="small"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <ArrowDropUp
                            style={{
                              color:
                                sortColumn === "qty" && sortOrder === "asc"
                                  ? "green"
                                  : "gray",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => toggleSortOrder("qty")}
                          size="small"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <ArrowDropDown
                            style={{
                              color:
                                sortColumn === "qty" && sortOrder === "desc"
                                  ? "red"
                                  : "gray",
                              position: "relative",
                              bottom: "4px",
                            }}
                          />
                        </IconButton>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Image
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData?.map((ProductsData, index) => (
                    <StyledTableRow key={ProductsData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ProductsData.brand_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ProductsData.category_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ProductsData.subcategory_name}
                      </StyledTableCell>
                      <StyledTableCell>{ProductsData.sku_code}</StyledTableCell>
                      <StyledTableCell>{ProductsData.name}</StyledTableCell>
                      <StyledTableCell>
                        {ProductsData.description}
                      </StyledTableCell>
                      <StyledTableCell>{ProductsData.mrp}</StyledTableCell>
                      <StyledTableCell>{ProductsData.qty}</StyledTableCell>
                      <StyledTableCell>
                        <img
                          src={ProductsData.image}
                          alt={ProductsData.name}
                          style={{ height: "50px", cursor: "pointer" }} // Cursor changes on hover
                          onClick={() =>
                            handleOpenImageModal(ProductsData.image)
                          } // Open modal on click
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(ProductsData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Products available</div>
        )}

        <Dialog open={openEditModal} maxWidth="md">
          <DialogTitle
            sx={{
              background: "linear-gradient(to right, #fff, #bfbfbf)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Update Products</span>
              <IconButton color="secondary" onClick={handleCloseEditModal}>
                <CancelIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    id="eid"
                    value={selectedData ? selectedData.id : ""}
                  />
                  <Grid item xs={4}>
                    <FormControl required fullWidth>
                      <InputLabel>Brand</InputLabel>
                      <Select
                        name="brand"
                        value={editData.brand}
                        onChange={(e) =>
                          handleEditModalChange("brand", e.target.value)
                        }
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        {brandData?.map((brand) => (
                          <MenuItem key={brand.id} value={brand.id}>
                            {brand.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl required fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        name="category"
                        value={editData.category}
                        onChange={(e) =>
                          handleEditModalChange("category", e.target.value)
                        }
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        {CategoryData?.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl required fullWidth>
                      <InputLabel>Sub Category</InputLabel>
                      <Select
                        name="subcategory"
                        value={editData.subcategory}
                        onChange={(e) =>
                          handleEditModalChange("subcategory", e.target.value)
                        }
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        {filteredSubcategories?.map((subcategory) => (
                          <MenuItem key={subcategory.id} value={subcategory.id}>
                            {subcategory.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <h5>Sku Code *</h5>
                    <TextField
                      id="sku_code"
                      label="Sku Code"
                      fullWidth
                      margin="normal"
                      name="sku_code"
                      defaultValue={editData.sku_code}
                      onChange={handleEditModalChange}
                      error={!!formErrors.sku_code}
                      helperText={formErrors.sku_code}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h5>Product Name *</h5>
                    <TextField
                      label="Product Name"
                      id="name"
                      fullWidth
                      margin="normal"
                      name="name"
                      defaultValue={editData.name}
                      onChange={handleEditModalChange}
                      error={!!formErrors.name}
                      helperText={formErrors.name}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h5>Mrp *</h5>
                    <TextField
                      label="Mrp"
                      id="mrp"
                      fullWidth
                      margin="normal"
                      name="mrp"
                      defaultValue={editData.mrp}
                      onChange={handleEditModalChange}
                      error={!!formErrors.mrp}
                      helperText={formErrors.mrp}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h5>Qty *</h5>
                    <TextField
                      label="Qty"
                      id="qty"
                      fullWidth
                      margin="normal"
                      name="qty"
                      defaultValue={editData.qty}
                      onChange={handleEditModalChange}
                      error={!!formErrors.qty}
                      helperText={formErrors.qty}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Description *</h5>
                    <TextField
                      label="Description"
                      id="description"
                      fullWidth
                      margin="normal"
                      name="description"
                      defaultValue={editData.description}
                      onChange={handleEditModalChange}
                      error={!!formErrors.description}
                      helperText={formErrors.description}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h5>Image Upload *</h5>
                    <TextField
                      type="file"
                      name="image"
                      id="image"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={handleEditImageChange}
                      sx={{
                        backgroundColor: "rgba(212,216,252, 0.7)",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {editData.image && !editpreviewImageUrl && (
                      <img
                        src={editData.image}
                        style={{ maxWidth: "100%", marginTop: "16px" }}
                      />
                    )}
                    {editpreviewImageUrl && (
                      <img
                        src={editpreviewImageUrl}
                        alt="Selected"
                        style={{ maxWidth: "25%", marginTop: "16px" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>
          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseEditModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEdit}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="product-image-modal"
        >
          <DialogTitle>
            <div style={{ display: "flex" }}>
              <span>Product Image</span>
              <IconButton onClick={handleCloseImageModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Product"
                style={{ width: "100%", height: "auto" }} // Larger version of the image
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
